import React, { useState, useEffect, forwardRef } from 'react';
import { Flex, Box, LabelValueGrid } from '../core';
import { util } from 'market-dto';
import { ExpContext, ExpressionEditorState, ExpContextField, SenseOption } from './exp-types';
import {  expNodeToString } from './exp-node-to-string';

interface Props {
    readonly expCtx:ExpContext;
    readonly expState:ExpressionEditorState;
}
export const DebugExp = ({ expState, expCtx }:Props) => {
    return (
        <Box bg="body" borderColor="alert" borderSides="all" p="2" m="2" style={{ maxWidth: '500px' }}>
            <Flex mt="1">
                &nbsp;
                { util.range(expState.exp.length).map(n => {
                    return <Flex flexDirection="column" key={n}>
                        <Box fg="loud" mx="half" textAlign="center">{ expState.exp[n] === ' ' ? '_' : expState.exp[n] }</Box>
                        <Box fg={expState.caret === n ? 'success' : 'faded' } mx="half" textAlign="center">{ n }</Box>
                    </Flex>
                })}
            </Flex>
            <LabelValueGrid>
                <Box textAlign="right" fg="faded">Raw Text:</Box>
                <Box style={{ fontFamily: 'courier new', whiteSpace:'pre' }}>{ expNodeToString(expState.rootNode, true) }</Box>
                <Box textAlign="right" fg="faded">Normalized Text:</Box>
                <Box style={{ fontFamily: 'courier new', whiteSpace:'pre' }}>{ expNodeToString(expState.rootNode, false) }</Box>
            </LabelValueGrid>
        </Box>
    )
}
