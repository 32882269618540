import { util } from 'market-dto';
import { ExpEvalResult, ExpOpDef } from './exp-types';

// BINARY OPERATORS ONLY (unary are special case and baked in...hardcoded...hard to untangle)

const noArrayCheck = (a:ExpEvalResult, b:ExpEvalResult) => {
    // run time check
    if (Array.isArray(a)) throw new Error('Array not allowed left-side: ' + a);
    if (Array.isArray(b)) throw new Error('Array not allowed right-side: ' + b);
}

const num = (x:ExpEvalResult) => {
    // run time check
    const result = Number(x);
    if (isNaN(result)) throw new Error('Expected number: ' + x);
    return result;
}

const boolCheck = (a:ExpEvalResult, b:ExpEvalResult) => {
    // run time check
    if (typeof a !== 'boolean') throw new Error('Expected left-side to be boolean: '+ a);
    if (typeof b !== 'boolean') throw new Error('Expected right-side to be boolean: ' + b);
}


// https://en.cppreference.com/w/c/language/operator_precedence
// I stole them from this (the precedences)

export const operatorDefs:ExpOpDef[] = [
    {
        name: '==',
        returnType: 'boolean',
        compareTypes: ['boolean', 'string', 'number'],
        // was 20
        precedence: 7,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            noArrayCheck(a, b); // ensures neither a,b are arrays
            return a == b;
        }
    },
    {
        name: '!=',
        returnType: 'boolean',
        compareTypes: ['boolean', 'string', 'number'],
        // was 20
        precedence: 7,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            noArrayCheck(a, b); // ensures neither a,b are arrays
            return a != b;
        }
    },
    {
        name: '<',
        returnType: 'boolean',
        compareTypes: ['number'],
        // was 30
        precedence: 6,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            return num(a) < num(b);
        }
    },
    {
        name: '<=',
        returnType: 'boolean',
        compareTypes: ['number'],
        // was 30
        precedence: 6,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            return num(a) <= num(b);
        }
    },
    {
        name: '>',
        returnType: 'boolean',
        compareTypes: ['number'],
        // was 30
        precedence: 6,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            return num(a) > num(b);
        }
    },
    {
        name: '>=',
        returnType: 'boolean',
        compareTypes: ['number'],
        // was 30
        precedence: 6,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            return num(a) >= num(b);
        }
    },
    {
        name: 'and',
        returnType: 'boolean',
        compareTypes: ['boolean'],
        // was 15
        precedence: 11,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            boolCheck(a, b);
            return a && b;
        }
    },
    {
        name: 'or',
        returnType: 'boolean',
        compareTypes: ['boolean'],
        // was 10
        precedence: 12,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            boolCheck(a, b);
            return a || b;
        }
    },
    {
        name: '+',
        returnType: 'number',
        compareTypes: ['number'],
        // was 40
        precedence: 4,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            return num(a) + num(b);
        }
    },
    {
        name: '-',
        returnType: 'number',
        compareTypes: ['number'],
        // was 40
        precedence: 4,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            return num(a) - num(b);
        }
    },
    {
        name: '*',
        returnType: 'number',
        compareTypes: ['number'],
        // was 50
        precedence: 3,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            return num(a) * num(b);
        }
    },
    {
        name: '/',
        returnType: 'number',
        compareTypes: ['number'],
        // was 50
        precedence: 3,
        evalFn: (a:ExpEvalResult, b:ExpEvalResult) => {
            const b2 = num(b);
            // Perhaps allow it and return 0 or infinitiy?
            if (b2 === 0) throw new Error('Attempted to divide ' + a + ' by zero');
            return num(a) / b2;
        }
    }
]

export const operatorDefByName = util.toDict(operatorDefs, x => x.name);

