export const checkSampleDataNeedsNesting = (obj:any) => {
    const result:any = {};
    // only gonna allow one deep checks.
    Object.entries(obj).forEach(([ key, val ]) => {
        if (key.includes('.')) {
            const arr = key.split('.');
            const obj = checkSampleDataNeedsNesting({ [arr[1]] : val });
            result[arr[0]] = obj;
        } else {
            result[key] = val;
        }
    })
    return result;
}