import React, { CSSProperties } from 'react';
import { getCss } from '../getCss';

interface HighlightTextProps {
	readonly txt:string;
	readonly hiTxt:string;
    readonly hiCss?:string;
}
export const HighlightText = ({ txt, hiTxt, hiCss="text-hi" }:HighlightTextProps) => {
	// This simply inherits fg/bg EXCEPT for whatever ends up being highlighted, which gets "text-hi" css.
	const hiTxtTrimmed = hiTxt.trim();
	if (!hiTxtTrimmed) return <span>{ txt }</span>;
	const re = new RegExp(hiTxtTrimmed, 'gi');	
	const indexes:number[] = [];
	// It's complicated. But it works, and, it preserves the case of the original text.
	// Note the mutable vars with the lets!
	let match;
	while ((match = re.exec(txt)) != null) indexes.push(match.index);
	if (indexes.length === 0) return <span>{ txt }</span>;
	const len = hiTxtTrimmed.length;
	let nextIndex = 0;
	return <>
		{ indexes.map((index, n) => {
			const startAt = nextIndex;
			nextIndex = index + len;
			return (
				<React.Fragment key={n}>
					{ startAt < index && <span>{ txt.substring(startAt, index) }</span> }
					{ <span className={hiCss}>{ txt.substr(index, len)}</span> }
				</React.Fragment>
			)
		})}
		{ nextIndex < txt.length && <span>{ txt.substring(nextIndex) }</span> }
	</> 
}
