import { ExpNode, ExpContext, ExpEvalStatus, ExpressionEditorState, ExpEvalResult } from './exp-types';
import { parseExpression } from './parse-expression';
import { isValidExpNode } from './is-valid-exp-node';
import { evalExpFromNode } from './eval-exp';
import { checkSampleDataNeedsNesting } from './check-sample-data-needs-nesting';
import { Examiner } from 'raccoon-engine';

// THIS IS WHERE THE EVALUATION IS DONE.
// THIS NEEDS TO BE THE ONLY PLACE WHERE IT IS DONE.

const evalExp = (expStr:string, root:ExpNode, expCtx:ExpContext, runData:any):ExpEvalResult => {
    if (expCtx.useMathJs) {
        return Examiner.examOneLiner(runData, expStr).fallsInside;
    } else {
        return evalExpFromNode(root, expCtx, runData);
    }
}

export const getEvalStatusFromString = (expStr:string, expCtx:ExpContext, runData:any):ExpEvalStatus => {

    const data = checkSampleDataNeedsNesting(runData);

    // We need to consoladate the following with "get expression editor state" guy, so logic is in one place.
    const isBlank = expStr.trim() === '' ? true : false;
    const parseResult = parseExpression(expStr, expCtx);
    const { node:rootNode, warnings } = parseResult;
    const isValidRegardlessOfMathJs = isValidExpNode(rootNode);
    const isTotallyValid = isValidRegardlessOfMathJs && warnings.length === 0 && rootNode?.valType !== 'unknown';
    // -----------------------------------------------------

    const validSyntax = isTotallyValid ? true : false;
    const canEval = validSyntax && rootNode ? true : false;
    // console.log('validSyntax', validSyntax);
    // console.log('canEval', canEval);
    // console.log('isTotallyValid', isTotallyValid);
    // console.log('expStr', expStr);
    const evalResult = canEval ? evalExp(expStr, rootNode!, expCtx, data) : false;
    return {
        isBlank,
        validSyntax,
        canEval,
        evalResult
    }
}

export const getEvalStatusFromEditorState = (expState:ExpressionEditorState, expCtx:ExpContext, runData:any):ExpEvalStatus => {

    const data = checkSampleDataNeedsNesting(runData);

    const isBlank = expState.exp.trim() === '' ? true : false;
    const validSyntax = expState.isTotallyValid ? true : false;
    const canEval = validSyntax && expState.rootNode ? true : false;
    // console.log('validSyntax', validSyntax);
    // console.log('canEval', canEval);
    const evalResult = canEval ? evalExp(expState.exp, expState.rootNode!, expCtx, data) : false;
    return {
        isBlank,
        validSyntax,
        canEval,
        evalResult
    }
}