import React from 'react';
import { NamedIconProps } from '../icon/icon';

export const IconColumns = ({ onClick, onMouseDown, css, title }:NamedIconProps) => {
    return (
        <svg
            onClick={onClick}
            onMouseDown={onMouseDown}
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={css}
        >
            { title && <title>{ title }</title> }
            <path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path>
        </svg>
    )
}