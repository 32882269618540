import React from 'react';
import { Icon } from '../icon';
import { FlexVCenter } from '../flex';
import { Box } from '../box';
import { SpacingSize, FontSize } from '../../types';

interface SpinLabelProps {
    readonly mt?:SpacingSize;
    readonly mb?:SpacingSize;
    readonly my?:SpacingSize;
    readonly fontSize?:FontSize;
    readonly children?:React.ReactNode;
}
export const SpinLabel = ({ mt, my, mb, children, fontSize="lg" }:SpinLabelProps) => {
    return (
        <FlexVCenter mt={mt} my={my} mb={mb} fontSize={fontSize}>
            <Icon name="loader" spinning={true} fg="default" />
            <Box fg="faded">
                &nbsp;
                { children }
            </Box>
        </FlexVCenter>
    )
}