import React, { useState, useEffect, useRef, forwardRef } from 'react';
import {
    ExpContext,
    SenseOption,
    SenseOptionIndexes,
    ExpressionEditorState,
    ExpNode,
    getOperators,
    MAX_DISPLAYED_SENSE_OPTIONS,
    KeyAndInitVal
} from './exp-types';

import { getCursorXY } from './get-cursor-xy';
import { usePopper } from 'react-popper';
import { SensePopper } from './sense-popper';
import { expNodeToHtml } from './exp-node-to-html';
import { acceptSenseOption } from './accept-sense-option';
import { getExpEditorState } from './get-exp-editor-state';
import './exp.css';

interface Coords { x:number, y:number };
interface KeyAndVal { readonly key:string, readonly val:string };

const sortSenseOptions = (a:SenseOption, b:SenseOption) => {
    // sort by alphabetical case insenstivie
    const v1 = a.value.toLowerCase();
    const v2 = b.value.toLowerCase();
    if (v1 > v2) return 1;
    if (v1 < v2) return -1;
    return 0;
}

const isFunction = (functionToCheck:any):boolean => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

const getEffCaret = (s:string, selectionStart:number|null):number => {
    if (s.length === 0 || selectionStart === null) return 0;
    return selectionStart;
}

interface Props {
    // readonly initVal:string; // rename to initVal
    readonly keyAndInitVal:KeyAndInitVal;
    readonly expCtx:ExpContext;
    readonly autoFocus?:boolean;
    readonly onChange:(expState:ExpressionEditorState) => void;
    readonly onEnter?:(expState:ExpressionEditorState, ctrlDown:boolean, shiftKey:boolean) => void;
    readonly onTab?:(expState:ExpressionEditorState) => void;
    readonly onFocus?:() => void;
    readonly onBlur?:() => void;
    readonly clearOnValidSubmit?:boolean;
}
export const ExpressionEditor = forwardRef((props:Props, forwardMe:React.Ref<HTMLInputElement>) => {

    const {
        keyAndInitVal,
        onChange,
        expCtx,
        onEnter,
        onTab,
        onBlur,
        clearOnValidSubmit,
        autoFocus,
        onFocus
    } = props;
    const lastVal = useRef<string>('');
    const lastActiveNodeId = useRef<string>('');

    const [ refElem, setRefElem ] = useState<HTMLInputElement | null>(null);    
    // const [ val, setVal ] = useState(keyAndInitVal.initVal);
    const [ kval, setKVal ] = useState<KeyAndVal>({ key: keyAndInitVal.key, val: keyAndInitVal.initVal });
    const lastKVal = useRef<KeyAndVal|null>({ key: keyAndInitVal.key, val: keyAndInitVal.initVal });
    const [ expState, setExpState ] = useState<ExpressionEditorState>(getExpEditorState(
        keyAndInitVal.key,
        keyAndInitVal.initVal,
        0,
        expCtx,
        [],
        []
    ))

    useEffect(() => {
        // console.log('big change, ', keyAndInitVal.initVal);
        const k = { key: keyAndInitVal.key, val: keyAndInitVal.initVal };
        // console.log('SET LAST KE VAL', k);
        lastKVal.current = k;
        setKVal(k);
        setExpState(getExpEditorState(
            keyAndInitVal.key,
            keyAndInitVal.initVal,
            0,
            expCtx,
            [],
            []
        ))
    }, [keyAndInitVal.key, keyAndInitVal.initVal])

    const [ fieldsAndFuncOptions, setFieldsAndFuncOptions ] = useState<SenseOption[]>([]);
    const [ opOptions, setOpOptions ] = useState<SenseOption[]>([]);
    const [ hasFocus, setHasFocus ] = useState<boolean>(false);
    const [ senseOptions, setSenseOptions ] = useState<SenseOption[] | null>(null);
    const [ expRootNode, setExpRootNode ] = useState<ExpNode | null>(null);
    const [ activeNode, setActiveNode ] = useState<ExpNode | null>(null);
    const [ hideSense, setHideSense ] = useState<boolean>(true);
    const [ xy, setXY ] = useState<Coords>({ x:0, y: 0});
    const [ popperElement, setPopperElement ] = useState<HTMLDivElement | null>(null);
    const [ indexes, setIndexes ] = useState<SenseOptionIndexes>({ start:0, active: 0 });
    const { styles, attributes } = usePopper(refElem, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [xy.x - 8, 8]
                }
            }
        ],
        strategy: 'fixed'
    })

    const setFocusFlag = (flag:boolean) => {
        setHasFocus(flag);
        if (flag && onFocus) onFocus();
        if (!flag && onBlur) onBlur();
    }

    useEffect(() => {
        if (!expCtx) return;
        // We make an assumption that fields/funcs/ops NEVER change once we are in DOM
        setFieldsAndFuncOptions([
            ...expCtx.fieldList.map((x):SenseOption => {
                return { value: x.id, type: 'field', priority:'neither' }
            }),
            ...expCtx.funcList.map((x):SenseOption => {
                return { value: x.name, type: 'func', priority:'neither' }
            })
        ].sort(sortSenseOptions))

        setOpOptions(getOperators().map((x):SenseOption => {
            return { value: x, type: 'op', priority:'neither' }
        }).sort(sortSenseOptions))
    }, [expCtx])

    useEffect(() => {
        if (!refElem) return;
        if (lastKVal.current) {
            // if key changed, forget about it.
            if (lastKVal.current.key !== kval.key) {
                console.log('Not really a change--new exp key');
                return;
            }
            // same value is okay, cuz, we might have simply moved caret
            // if value the same, key the same, forget about it.
            // if (lastKVal.current.val === kval.val) return;
        }
        chk();
    }, [kval.key, kval.val])

    useEffect(() => {
        // Forward the input ref AND keep it here. We need it.
        if (!refElem) return;
        if (forwardMe) {
            if (isFunction(forwardMe)) {
                (forwardMe as any)(refElem);
            } else {
                (forwardMe as any).current = refElem;
            }
        }
        // console.log('!!!!!! THE INIT CHECK');
        theCheck(true); // initial check!
    }, [refElem])

    const theCheck = (initCheck?:boolean) => {
        if (!refElem) return;
        const nextVal = refElem.value;
        // const valChanged = lastVal.current !== nextVal;
        lastVal.current = nextVal;
        const nextCaret = getEffCaret(nextVal, refElem.selectionStart);
        const nextState = getExpEditorState(keyAndInitVal.key, nextVal, nextCaret, expCtx, fieldsAndFuncOptions, opOptions);
        const xy = getCursorXY(refElem, nextCaret);
        const isPrevCharSpace = nextCaret > 0 && nextVal.length > nextCaret-1 && nextVal[nextCaret-1] === ' ';
        const isCurrCharSpace = nextVal.length > nextCaret && nextVal[nextCaret] === ' ';
        const caretAtEnd = nextCaret === nextVal.length;
        const nextActiveNode = nextState.activeNode;
        const activeNodeChanged = nextActiveNode && lastActiveNodeId.current !== nextActiveNode.id;
        lastActiveNodeId.current = nextActiveNode ? nextActiveNode.id! : '';
        setXY(xy);
        setExpRootNode(nextState.rootNode);
        setActiveNode(nextActiveNode);
        setIndexes({ active: 0, start: 0});
        setSenseOptions((caretAtEnd && isPrevCharSpace) || isCurrCharSpace || !hasFocus ? null : nextState.senseOpts);
        setHideSense(activeNodeChanged ? false : hideSense);
        setExpState(nextState);
        
        // if (initCheck) return;
        // console.log('THE CHECK CHANGE', nextState.exp, nextState.expKey);
        // "change" is because you need the initial editor state! change is a little misleading.
        onChange(nextState);
    }

    const setSenseActiveIndex = (nextActiveIndex:number) => {
        const { start } = indexes;
        if (nextActiveIndex < start) {
            // obviously, we must lower startIndex
            setIndexes({
                start: nextActiveIndex,
                active: nextActiveIndex
            })
        } else if (nextActiveIndex - start >= MAX_DISPLAYED_SENSE_OPTIONS) {
            setIndexes({
                start: nextActiveIndex - MAX_DISPLAYED_SENSE_OPTIONS + 1,
                active: nextActiveIndex
            })
        } else {
            setIndexes({
                start,
                active: nextActiveIndex
            })    
        }
    }

    const acceptCurrentActive = ():boolean => {
        // they hit enter or tab or something. accent current "active"      
        if (hideSense) return false;
        if (!activeNode) return false;
        if (!senseOptions || senseOptions.length === 0) return false;
        const { active } = indexes;
        const opt = senseOptions[active];
        if (!opt) throw new Error('active senseOption index out of bounds');
        const nextVal = acceptSenseOption(kval.val, activeNode, opt, expCtx);
        setKVal({ key: kval.key, val: nextVal });
        setHideSense(true);
        return true;
    }

    const chk = () => setTimeout(() => theCheck(), 0);

    const keyDown = (e:React.KeyboardEvent<HTMLInputElement>) => {

        const { start, active } = indexes;

        switch (e.key) {
            case 'ArrowDown': {
                if (senseOptions && active < senseOptions.length-1) setSenseActiveIndex(active+1);
                e.preventDefault();
                return;
            }
            case 'ArrowUp': {
                if (active > 0) setSenseActiveIndex(active-1);
                e.preventDefault();
                return;
            }
        }

        // cANNOT DO THIS CUZ...SET TIMEOUT...
        // const currCaret = getEffCaret(refElem!.value, refElem!.selectionStart);
        // console.log('P', currCaret, caret);
        // if (currCaret !== caret) {
        //     console.log('DOING THE CHECK', currCaret, caret);
        //     chk();
        // }
        
        switch (e.key) {

            case 'Home':
            case 'End':
            case 'ArrowLeft':
            case 'ArrowRight': {
                chk();
                return;
            }

            case ' ': { // SPACE BAR
                if (e.ctrlKey) setHideSense(false);
                return;
            }
            
            case 'Tab': {
                e.preventDefault();
                if (!expState) return;
                if (acceptCurrentActive()) return;
                if (onTab) {
                    onTab(expState);
                    if (clearOnValidSubmit && expState.isValid) setKVal({ key:kval.key, val: '' });
                }
                return;
            }
            case 'Enter': {
                e.preventDefault();
                if (!expState) {
                    console.log('no state?');
                    return;
                }
                if (acceptCurrentActive()) {
                    console.log('accept active');
                    return;
                }
                if (onEnter) {                    
                    onEnter(expState, e.ctrlKey ? true : false, e.shiftKey ? true : false);
                    if (clearOnValidSubmit && expState.isValid) setKVal({ key:kval.key, val: '' });
                }
                return;
            }
            case 'Escape': {
                setHideSense(true);
                e.preventDefault();
                return;
            }
        }
    }

    const onSenseClick = (opt:SenseOption, optIndex:number) => {
        // Do we care about indexes? I guess not...
        if (!activeNode) return;
        const nextVal = acceptSenseOption(kval.val, activeNode, opt, expCtx);
        setKVal({ key: kval.key, val: nextVal });
        setHideSense(true);
        if (refElem) refElem.focus();
    }

    const overlayLeft = refElem ? -refElem.scrollLeft : '0';

    return (
        <>
            <div
                className="ee-popper-container"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
            >
                { !hideSense && senseOptions && senseOptions.length > 0 && (
                    <SensePopper
                        opts={senseOptions}
                        indexes={indexes}
                        onClick={onSenseClick}
                        maxDisplayOpts={MAX_DISPLAYED_SENSE_OPTIONS}
                    />
                ) }
            </div>
            <div className="ee-container">
                <input
                    autoFocus={autoFocus}
                    onFocus={() => setFocusFlag(true)}
                    onBlur={() => {
                        setFocusFlag(false);
                        setHideSense(true);
                    }}
                    spellCheck={false}
                    className="ee-input"
                    type="text"
                    // onFocus={focus}
                    // onKeyUp={kup}
                    onClick={() => theCheck()}
                    onKeyDown={keyDown}
                    // WE MUST SET VALUE LIKE THIS
                    onChange={e => setKVal({ key:kval.key, val: e.target.value })}
                    ref={setRefElem}
                    value={kval.val}
                />
                <div className="ee-overlay">
                    <div style={{ marginLeft:overlayLeft }} >
                        { expNodeToHtml(expRootNode, hasFocus ? activeNode : null) }
                    </div>
                </div>
            </div>
        </>
    )
})
