import React from 'react';
import { Box, BoxProps } from '../box/box';
import { JustifyContent } from '../../types';

type GridGapSize = "0" | "1px" | "half" | "1" | "2";

const getGridColumnGap = (gap?:GridGapSize):string => {
    if (!gap) return '0'
    if (gap === '0') return '0';
    if (gap === '1px') return '1px';
    if (gap === 'half') return 'var(--space-half)';
    if (gap === '1') return 'var(--space1)';
    if (gap === '2') return 'var(--space2)';
    return '0';
}


const getGridRowGap = (gap?:GridGapSize):string => {
    if (!gap) return '0'
    if (gap === '0') return '0';
    if (gap === '1px') return '1px';
    if (gap === 'half') return 'var(--space-half)';
    if (gap === '1') return 'var(--space1)';
    if (gap === '2') return 'var(--space2)';
    return '0';
}

interface Props {
    readonly cols:number;
    readonly children:any;
    readonly gap?:GridGapSize
}
export const CssGrid = ({ cols, children, gap = "2" }:Props) => {
    // Very rarely need this. And when we do, we just want basic grid, same size cols.
    // No need to inherit from Box.

    const gridStyle:React.CSSProperties = {
        display: 'grid',
        gridColumnGap: getGridColumnGap(gap),
        gridTemplateColumns: `repeat(${ cols }, 1fr)`
    }
    return <div style={gridStyle}>{ children }</div>;
}

// One day reconcile the two of these CSSGrid components!!!

interface CssGridBoxProps extends BoxProps {
    readonly cols:number;
    readonly children:any;
    readonly gap?:GridGapSize;
    readonly rowGap?:GridGapSize;
    readonly justifyContent?:JustifyContent;
    readonly sameSize?:boolean;
}
export const CssGridBox = ({ sameSize, cols, rowGap, justifyContent="space-around", gap="2", ...rest }:CssGridBoxProps) => {
    const gridStyle:React.CSSProperties = {
        display: 'grid',
        columnGap: getGridColumnGap(gap),
        rowGap: getGridRowGap(rowGap),
        gridTemplateColumns: `repeat(${ cols }, ${ sameSize ? '1fr' : 'auto'})`,
        alignContent: 'space-around',
        justifyContent
    }
    return <Box style={gridStyle} {...rest} />;
}


export const TableLikeCssGrid = ({ children, ...props }:CssGridBoxProps) => {
    return <CssGridBox justifyContent="flex-start" alignItems="center" {...props}>{ children }</CssGridBox>
}

interface LabelValueGridProps {
    readonly children:React.ReactNode;
    readonly gap?:"half"|"1"|"2";
}
export const LabelValueGrid = ({ children, gap="1" }:LabelValueGridProps) => {
    // ALWAYS two columns
    const gridStyle:React.CSSProperties = {
        display: 'grid',
        gridColumnGap: 'var(--space-' + gap + ')',
        gridRowGap: 'var(--space-' + gap + ')',
        gridTemplateColumns: 'auto 1fr',
        justifyContent: 'flex-start',
        alignItems: 'center'
    };
    return <div style={gridStyle}>{ children }</div>;
}


type TableLikeGridColSize = "auto" | "*";
interface TableLikeGridProps {
    readonly colSizes:TableLikeGridColSize[];
    readonly children:any;
}
export const TableLikeGrid = ({ colSizes, children }:TableLikeGridProps) => {
    const templateCols = colSizes.map(x => x === '*' ? '1fr' : 'auto').join(' ');
    const gridStyle:React.CSSProperties = {
        display: 'grid',
        gridColumnGap: 'var(--space1)',
        gridRowGap: 'var(--space1)',
        gridTemplateColumns: templateCols,
        justifyContent: 'flex-start',
        alignItems: 'center'
    };
    return <div style={gridStyle}>{ children }</div>;
}
