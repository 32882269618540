import {
    EXP_INVALID_CHAR,
    EXP_OPS,
    ExpArrayNode,
    ExpFunctionCallNode,
    ExpIdentifierNode,
    ExpListItemNode,
    ExpNode,
    ExpOp,
    ExpUnaryOp,
    ExpParenScopedNode,
    ExpUnaryNode,
    getOperators,
    UNIARY_OPS,
    NON_ALPHA_OPS_FIRST_LETTERS,
    ExpStringConstantNode,
    ExpParseResult,
    ExpContext
} from './exp-types';
import { assignIds } from './assign-ids';
import { typeChecker } from './type-checker';
import { enforceOrderOfOps } from './enforce-order-of-ops';
import { util } from 'market-dto';

// For now, only model is sent around...yuck.
interface TreeInfo {
    readonly expCtx:ExpContext;
    readonly openParens:number;
    readonly openFnParens:number;
    readonly openBrackets:number;
}

const incRegularParen = (ti:TreeInfo):TreeInfo => {
    return {
        ...ti,
        openParens: ti.openParens + 1
    }
}

const incFnParen = (ti:TreeInfo):TreeInfo => {
    return {
        ...ti,
        openParens: ti.openParens + 1,
        openFnParens: ti.openFnParens + 1
    }
}

const incBracket = (ti:TreeInfo):TreeInfo => {
    return {
        ...ti,
        openBrackets: ti.openBrackets + 1
    }
}

const getOpFromWord = (word:WordInfo|null):ExpOp => {
    if (!word) return EXP_INVALID_CHAR;
    if (word.wordType === "op" || word.wordType === "unary-op") return word.value as ExpOp;
    if (word.wordType === "invalid-op") return EXP_INVALID_CHAR;  // i know, redudnant
    if (EXP_OPS.includes(word.value as any)) return word.value as ExpOp;
    return EXP_INVALID_CHAR;
}

const xNode = (s:string, start:number, end:number, ti:TreeInfo):ExpNode => {
    return {
        type: "ConstantNode",
        start: start,
        end: end,
        value: EXP_INVALID_CHAR,
        raw: s.substring(start, end)
    }
}

const wordToTerminalNode = (s:string, word:WordInfo, ti:TreeInfo):ExpNode => {
    if (word.wordType === 'number') {
        return {
            type: 'ConstantNode',
            start: word.start,
            end: word.end,
            raw: s.substring(word.start, word.end),
            value: word.value
        }
    } else {
        return {
            type: 'IdentifierNode',
            start: word.start,
            end: word.end,
            raw: s.substring(word.start, word.end),
            value: word.value,
            isValid: ti.expCtx.fieldByName[word.value] ? true : false
        }
    }
}

const getListItemNode = (w:WordInfo, s:string, startIndex:number, ti:TreeInfo):ExpNode => {
    return parseRecurse(s, startIndex, ti) ?? xNode(s, w.end, w.end, ti);
}

const parseListItems = (s:string, startAt:number, ti:TreeInfo, ender:WordType):ExpListItemNode[] => {
    // We get something like this:
    //  023456789
    // "abc(2,3,4,5)"
    //      ^------ startAt = 5
    const result:ExpListItemNode[] = [];
    let startIndex = startAt;
    // The 100 limit is to prevent infinite looping (if, by bug, that becomes possible)
    for (let i=0; i < 100; i++) {

        // Look for the comma first
        const cword = getNextWord(s, startIndex);
        if (!cword) return result;
        if (cword.wordType === ender) return result;

        if (i === 0) {
            // treat the comma word as the word.
            const item = getListItemNode(cword, s, startIndex, ti);
            result.push({
                type: 'ListItemNode',
                start: startIndex,
                end: item.end,
                hasValidPrepend: true,
                raw: s.substring(startIndex, item.end),
                rawPrepend: s.substring(startIndex, item.start),
                prepend: '', // no comma for 0th
                item: item
            })
            startIndex = item.end;
            continue;
        }

        // normal. comma word better be a comma
        if (cword.wordType !== 'comma') {
            // Expected a comma, got something else
            result.push({
                type: 'ListItemNode',
                start: startIndex,
                end: cword.end,
                hasValidPrepend: false,
                raw: s.substring(startIndex, cword.end),
                rawPrepend: s.substring(startIndex, cword.end),
                prepend: ',',
                item: xNode(s, cword.end, cword.end, ti)
            })
            startIndex = cword.end;
            continue;
        }

        // We should be looking for ) or ]  !!!!

        // as expected.
        const nextWord = getNextWord(s, cword.end);
        if (!nextWord) {
            result.push({
                type: 'ListItemNode',
                start: startIndex,
                end: cword.end,
                hasValidPrepend: true,
                raw: s.substring(startIndex, cword.end),
                rawPrepend: s.substring(startIndex, cword.end),
                prepend: ',',
                item: xNode(s, cword.end, cword.end, ti)
            })
            return result;
        }
        const item = getListItemNode(nextWord, s, cword.end, ti);
        result.push({
            type: 'ListItemNode',
            start: startIndex,
            end: item.end,
            hasValidPrepend: true,
            raw: s.substring(startIndex, cword.end),
            rawPrepend: s.substring(startIndex, cword.end),
            prepend: ',',
            item: item
        })
        startIndex = item.end;
    }
    return result;
}

const parseParenNode = (s:string, word:WordInfo, ti:TreeInfo) => {
    if (word.wordType !== 'open-paren') throw new Error('impossible');

    const childNode = parseRecurse(s, word.end, incRegularParen(ti)) ?? xNode(s, word.end, word.end, incRegularParen(ti));
    const endParenIndex = getNextEndParen(s, childNode.end);
    const endParenFound = endParenIndex !== -1;
    const endIndex = endParenFound ? endParenIndex + 1: childNode.end;

    const parenNode:ExpParenScopedNode = {
        type: 'ParenScopedNode',
        start: word.start,
        end: endIndex,
        endParenFound,
        rawOpenParen: s.substring(word.start, word.end),
        rawCloseParen: endParenFound ? s.substring(childNode.end, endIndex) : '',
        raw: s.substring(word.start, endIndex),
        child: childNode
    }
    return parenNode;
}


const parseArrayNode = (s:string, word:WordInfo, ti:TreeInfo):ExpArrayNode => {
    if (word.wordType !== "open-bracket") throw new Error('impossible');
    const argNodes = parseListItems(s, word.end, incBracket(ti), 'close-bracket');

    const argEnd = argNodes.length === 0 ? word.end : argNodes[argNodes.length-1].end;
    const endBracketIndex = getNextEndBracket(s, argEnd);
    const endBracketFound = endBracketIndex !== -1;
    const endIndex = endBracketFound ? endBracketIndex + 1: argEnd;
    const arrayNode:ExpArrayNode = {
        type:'ArrayNode',
        start:word.start,
        end:endIndex,
        raw: s.substring(word.start, endIndex),
        rawOpenBracket: s.substring(word.start, word.end),
        rawCloseBracket: endBracketFound ? s.substring(argEnd, endIndex) : '',
        endBracketFound,
        items: argNodes
    }
    return arrayNode;
}

const parseFuncNode = (s:string, word:WordInfo, ti:TreeInfo) => {
    if (word.wordType !== 'fn-name') throw new Error('impossible');

    const argNodes = parseListItems(s, word.end, incFnParen(ti), 'close-paren');
    const argEnd = argNodes.length === 0 ? word.end : argNodes[argNodes.length-1].end;
    const endParenIndex = getNextEndParen(s, argEnd); // +1 on end removed
    const endParenFound = endParenIndex !== -1;
    const endIndex = endParenFound ? endParenIndex + 1: argEnd;

    const funNode:ExpFunctionCallNode = {
        type: 'FunctionCallNode',
        start: word.start,
        end: endIndex,
        endParenFound,
        raw: s.substring(word.start, endIndex),
        rawFnName: s.substring(word.start, word.end),
        rawCloseParen: endParenFound ? s.substring(argEnd, endIndex) : '',
        fnName: word.value,
        args: argNodes,
        isValidFnName: ti.expCtx.funcByName[word.value] ? true : false
    }
    return funNode;
}

const checkIfNodeIsLeftSideOfExpression = (s:string, node:ExpNode, ti:TreeInfo):ExpNode => {
    // You're only an expression node if AFTER the parenNode we find a word.
    const nextWord = getNextWord(s, node.end);

    if (nextWord === null) return node;
    
    // Parens are not made equal!
    // (a < b) == har(a, b)
    // One allows comma separated things, the other does not!
    // we distinguish: openParens (either) and openFnParens (allow comma separated)

    if (nextWord.wordType === 'close-paren' && ti.openParens > 0) return node;
    if (nextWord.wordType === 'close-bracket' && ti.openBrackets > 0) return node;
    if (nextWord.wordType === 'comma'
        && (ti.openFnParens > 0 || ti.openBrackets > 0)) return node;

    const rightSideNode = parseRecurse(s, nextWord.end, ti) ?? xNode(s, nextWord.end, s.length, ti);
    const op = getOpFromWord(nextWord);
    return {
        type: 'ExpressionNode',
        start: node.start, // startAt ?
        end: rightSideNode.end,
        raw: s.substring(node.start, rightSideNode.end),
        op: op,
        isValidOp: op !== '✖',
        rawOp: s.substring(node.end, rightSideNode.start),
        left: node,
        right: rightSideNode
    }
}

const parseUnaryNode = (s:string, word:WordInfo, ti:TreeInfo) => {
    if (word.wordType !== 'unary-op') throw new Error('impossible');
    // we do NOT wanna do a full parseJeff.
    // This is only going to apply directly to the node to the right.
    // unless the guy to the right is open paren!
    const unaryOp = word.value as ExpUnaryOp;
    const nextWord = getNextWord(s, word.end);
    if (nextWord) {
        if (nextWord.wordType === 'open-paren') {
            const childNode = parseRecurse(s, nextWord.end, ti) ?? xNode(s, nextWord.end, nextWord.end, ti);
            const unaryNode:ExpUnaryNode = {
                type: 'UnaryNode',
                start: word.start,
                end: childNode.end,
                op: unaryOp,
                rawOp: s.substring(word.start, word.end),
                raw: s.substring(word.start, childNode.end),
                child: childNode
            }
            return unaryNode;
        } else if (nextWord.wordType === 'number' || nextWord.wordType === 'string') {
            const child = wordToTerminalNode(s, nextWord, ti);
            const unaryNode:ExpUnaryNode = {
                type: 'UnaryNode',
                start: word.start,
                end: child.end,
                op: unaryOp,
                rawOp: s.substring(word.start, word.end),
                raw: s.substring(word.start, child.end),
                child: child
            }
            return unaryNode;
        } else {
            const child = xNode(s, nextWord.start, nextWord.end, ti);
            const unaryNode:ExpUnaryNode = {
                type: 'UnaryNode',
                start: word.start,
                end: child.end,
                op: unaryOp,
                rawOp: s.substring(word.start, word.end),
                raw: s.substring(word.start, child.end),
                child: child
            }
            return unaryNode;
        }
    }
    // No next word, so...
    const child = xNode(s, word.end, word.end, ti);
    const unaryNode:ExpUnaryNode = {
        type: 'UnaryNode',
        start: word.start,
        end: child.end,
        op: unaryOp,
        rawOp: s.substring(word.start, word.end),
        raw: s.substring(word.start, child.end),
        child: child
    }
    return unaryNode;
}

const parseSingleQuoteNode = (s:string, word:WordInfo, ti:TreeInfo) => {
    if (word.wordType !== 'single-quote') throw new Error('impossible');
    const nextSingleQuoteIndex = getNextSingleQuote(s, word.end);
    const endQuoteFound = nextSingleQuoteIndex !== -1;
    const endIndex = endQuoteFound ? nextSingleQuoteIndex + 1: s.length; // TAKE THE ENTIRE REST OF STRING!

    const quoteNode:ExpStringConstantNode = {
        type: 'StringConstantNode',
        start: word.start,
        end: endIndex,
        endQuoteFound,
        raw: s.substring(word.start, endIndex),
        value: s.substring(word.startVal, endIndex),
    }
    return quoteNode;
}

const parseRecurse = (s:string, startAt:number, ti:TreeInfo):ExpNode|null => {
    
    const word = getNextWord(s, startAt);
    if (!word) {
        // end of the string
        return null;
    }

    if (word.wordType === 'single-quote') {
        const quoteNode = parseSingleQuoteNode(s, word, ti);
        if (!quoteNode.endQuoteFound) return quoteNode;
        return checkIfNodeIsLeftSideOfExpression(s, quoteNode, ti);
    }

    if (word.wordType === 'unary-op') {
        // TODO: see if nextLevel is right there (2nd one)
        return checkIfNodeIsLeftSideOfExpression(s, parseUnaryNode(s, word, ti), ti);
    }

    if (word.wordType === 'open-bracket') {
        const arrayNode = parseArrayNode(s, word, ti);
        if (!arrayNode.endBracketFound) return arrayNode;
        return checkIfNodeIsLeftSideOfExpression(s, arrayNode, ti);
    }

    if (word.wordType === 'fn-name') {
        const funNode = parseFuncNode(s, word, ti);
        if (!funNode.endParenFound) return funNode;
        return checkIfNodeIsLeftSideOfExpression(s, funNode, ti);
    }
    
    if (word.wordType === 'open-paren') {
        // we need to look ahead to where we close
        const parenNode = parseParenNode(s, word, ti);
        if (!parenNode.endParenFound) return parenNode;
        return checkIfNodeIsLeftSideOfExpression(s, parenNode, ti);
    }

    // Regular old terminal node.
    const node = wordToTerminalNode(s, word, ti);
    return checkIfNodeIsLeftSideOfExpression(s, node, ti)
}

// | "single-quoted-string"
type WordType =
    | "string"
    | "number"
    | "single-quote"
    | "open-paren"
    | "close-paren"
    | "fn-name"
    | "comma"
    | "op"
    | "invalid-op"
    | "open-bracket"
    | "close-bracket"
    | "unary-op";

interface WordInfo {
    readonly wordType:WordType;
    readonly value:string;
    readonly startVal:number;
    readonly start:number;
    readonly end:number; // EXCLUSIVE end (does not include char found there)
}

const getNextWord = (s:string, startAt:number):WordInfo | null => {
    return getWord(s, startAt, getNextNonSpaceIndex(s, startAt));
}

const getWord = (s:string, start:number, startVal:number):WordInfo | null => {
    if (startVal === -1) return null;

    const firstLetter = s[startVal];
    if (firstLetter === ' ') throw new Error('HOW IS FIRST LETTER BLANK');
    
    // Order matters here.
    if (isFirstLetterUnaryOperator(firstLetter)) {
        return {
            start,
            startVal: startVal,
            end: startVal+1,
            wordType: 'unary-op',
            value: firstLetter
        }
    } else if (isDigitOrDot(firstLetter)) {
        return getNumberWord(s, start, startVal);
        // } else if (firstLetter === '[') {
        //     // array begins
    } else if (isFirstLetterNonAlphaOperator(firstLetter)) {
        // except for "and" and "or", if you get here,
        // the first letter begins an operator (such as ">" or "=")
        return getOperatorWord(s, start, startVal);
    } else if (firstLetter === '\'') {
        return {
            start,
            startVal: startVal,
            end: startVal+1,
            wordType: 'single-quote',
            value: '\''
        }
    } else if (firstLetter === ',') {
        return {
            start,
            startVal: startVal,
            end: startVal+1,
            wordType: 'comma',
            value: ','
        }
    } else if (firstLetter === '[') {    
        return {
            start,
            startVal: startVal,
            end: startVal+1,
            wordType: 'open-bracket',
            value: '['
        }
    } else if (firstLetter === ']') {    
        return {
            start,
            startVal: startVal,
            end: startVal+1,
            wordType: 'close-bracket',
            value: ']'
        }
    } else if (firstLetter === '(') {
        return {
            start,
            startVal: startVal,
            end: startVal+1,
            wordType: 'open-paren',
            value: '('
        }
    } else if (firstLetter === ')') {
        return {
            start,
            startVal: startVal,
            end: startVal+1,
            wordType: 'close-paren',
            value: ')'
        }
    } else {
        return getSymbolWord(s, start, startVal);
    }
}

const getOperatorWord = (s:string, start:number, startVal:number):WordInfo | null => {
    // We know the first letter begins an operator.
    // Keep advancing until we no longer can be an operator,
    // Then, take what we have, if operator, return valid operator, else, invalid operator (including partial)
    let ops:string[] = EXP_OPS.slice(0);
    let i = startVal;
    for (; i < s.length; i++) {
        const opStr = s.substring(startVal, i+1);
        ops = ops.filter(op => op.startsWith(opStr));
        if (ops.length === 1 && isValidOp(opStr)) {
            return {
                wordType: "op",
                end: i+1,
                start: start,
                startVal: startVal,
                value: s.substring(startVal, i+1)
            }
        }
        if (ops.length === 0) break;
    }
    const val = s.substring(startVal, i);
    return {
        wordType: isValidOp(val) ? "op" : "invalid-op",
        end: i,
        start: start,
        startVal: startVal,
        value: val
    }    
}

const getSymbolWord = (s:string, start:number, startVal:number):WordInfo | null => {
    // This can be "asdf(" or "abc.def" or "pig65"
    // What ends the word is a space or "("
    for (let i=startVal; i < s.length; i++) {
        const c = s[i];
        if (isWordTerminator(c)) {
            const endIndex = c === '(' ? i + 1 : i;
            return {
                wordType: c === '(' ? 'fn-name': 'string',
                end: endIndex,
                start: start,
                startVal: startVal,
                value: s.substring(startVal, i) // do not add ( for fun names
            }
        }
    }
    return {
        wordType: "string",
        end: s.length,
        start: start,
        startVal: startVal,
        value: s.substring(startVal)
    }
}

const getNumberWord = (s:string, start:number, startVal:number):WordInfo | null => {

    let hasDot = false;
    for (let i=startVal; i < s.length; i++) {
        const c = s[i];

        if (c === '.') {
            if (hasDot)  {
                // Error. ignore it for now.
            }
            hasDot = true;
        }
        if (!isDigitOrDot(c)) {
            return {
                wordType: "number",
                end: i,
                start: start,
                startVal: startVal,
                value: s.substring(startVal, i)
            }
        }
    }
    return {
        wordType: "number",
        end: s.length,
        start: start,
        startVal: startVal,
        value: s.substring(startVal)
    }
}

const isFirstLetterUnaryOperator = (c:string) => {
    // I think we can assume we won't have any multi-character unary operators.
    if (UNIARY_OPS.includes(c)) return true;
    return false;
}
const isFirstLetterNonAlphaOperator = (c:string) => {
    if (NON_ALPHA_OPS_FIRST_LETTERS.includes(c)) return true;
    return false;
}

const isWordTerminator = (c:string) => {
    // any first char of operator is a terminator.
    // a space
    // paren, end paren
    // WIAT: and or!!!
    // With the exception of any operator that begins with a letter
    if (isFirstLetterNonAlphaOperator(c)) return true;
    if (c === '[' || c === ']') return true;
    if (c === '(' || c === ')') return true;
    if (c === ',') return true;
    if (c === ' ') return true;
    return false;
}

const isValidOp = (s:string) => EXP_OPS.some(x => x === s);
const isSpace = (c:string) => c === ' '; // for now, jsut one kind of whitespace
const isDigitOrDot = (c:string) => (c >= '0' && c <= '9') || c === '.';
const isDigit = (c:string) => (c >= '0' && c <= '9');

const getNextEndBracket = (s:string, startAt:number) => {
    for (let i=startAt; i < s.length; i++) {
        if (s[i] === ']') return i;
    }
    return -1;
}

const getNextEndParen = (s:string, startAt:number) => {
    for (let i=startAt; i < s.length; i++) {
        if (s[i] === ')') return i;
    }
    return -1;
}

const getNextSingleQuote = (s:string, startAt:number) => {
    for (let i=startAt; i < s.length; i++) {
        if (s[i] === '\'') return i;
    }
    return -1;
}

const getNextComma = (s:string, startAt:number) => {
    for (let i=startAt; i < s.length; i++) {
        if (s[i] === ',') return i;
    }
    return -1;
}

const getNextNonSpaceIndex = (s:string, startingAt:number) => {
    for (let i=startingAt; i < s.length; i++) {
        if (s[i] !== ' ') return i;
    }
    return -1;
}

const getNextSpaceIndex = (s:string, startingAt:number) => {
    for (let i=startingAt; i < s.length; i++) {
        if (s[i] === ' ') return i;
    }
    return -1;
}

const getPrevSpaceIndex = (s:string, startingAt:number) => {
    for (let i=startingAt; i >= 0; i--) {
        if (s[i] === ' ') return i;
    }
    return -1;
}

const getPrevNonSpaceIndex = (s:string, startingAt:number) => {
    for (let i=startingAt; i >= 0; i--) {
        if (s[i] !== ' ') return i;
    }
    return -1;
}

const preserveTrailingSpaces = (node:ExpNode | null, s:string):ExpNode | null => {
    if (!node) return null;
    return {
        ...node,
        end: s.length
    }
}

export const parseExpression = (s:string, expCtx:ExpContext):ExpParseResult => {
    const start = new Date().getTime();
    const ti:TreeInfo = {
        expCtx,
        openParens: 0,
        openFnParens: 0,
        openBrackets: 0
    }
    const root = preserveTrailingSpaces(parseRecurse(s, 0, ti), s);
    if (!root) return {
        node: null,
        warnings: []
    }


    // console.log('RAW', JSON.stringify(root, null, 4));
    // Order of operations must be enforced by recursively modifying the tree.
    // However, you may have to make multiple passes!

    let reordered = root;
    let before:ExpNode = root;
    let n = 0;
    const maxShuffling = 10;
    
    do {
        before = reordered;
        reordered = enforceOrderOfOps(reordered) as ExpNode;
        n++;
    } while (!util.deepEqual(before, reordered) && n < maxShuffling);

    // console.log('passes required', n);

    // console.log('AFTER ENFORCED ORDER:\n', JSON.stringify(reordered, null, 4) + '\n');
    assignIds(reordered!); // MUTABLE
    const result = typeChecker(reordered!, expCtx);    
    const end = new Date().getTime();
    // console.log('PARSING EXPRESSION WITH 3 PASSES TOOK ', (end - start), 'ms');
    // console.log(JSON.stringify(result, null, 4));
    return result;
}