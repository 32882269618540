import { FlatJSONSchema } from 'market-dto';
import { ExpContext, ExpContextField, ExpValueType, ExpFunctionDef } from './exp-types';
import { extendedFunctions, FuncSignature } from 'raccoon-engine';
import { util } from 'market-dto';
import { getFuncs } from './sample-funcs';
import { toFieldsFromSchema } from './to-fields-from-schema';

const notFunc:FuncSignature = {
    name: 'not',
    returnType: 'boolean',
    args: [['boolean']]
}

const trueField:ExpContextField = {
    id: 'true',
    label: 'true',
    schema: {
        type: 'boolean'
    }
}

const falseField:ExpContextField = {
    id: 'false',
    label: 'false',
    schema: {
        type: 'boolean'
    }
}

export const flatJsonSchemaToExpCtx = (schema:FlatJSONSchema, useMathJs?:boolean):ExpContext => {

    const list = toFieldsFromSchema(schema).concat([trueField, falseField]);
    const funcs = useMathJs ? extendedFunctions.concat(notFunc) : getFuncs();

    return {
        useMathJs,
        fieldList: list,
        funcList: funcs,
        funcByName: util.toDict(funcs, x => x.name),
        fieldByName: util.toDict(list, x => x.id)
    }
}
